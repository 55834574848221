import ReactGA from 'react-ga'
import analytics from '../data/analytics.json'

// Tracking IDs for Google Analytics
const trackingIds = ['production', 'alternate']


/**
 * Checks whether the app host is running in Production or not.
 *
 * @exports isProduction
 * @param {String} host Host where the app is running.
 * @return {Boolean} Host is Production, or not.
*/
export function isProduction(host) {
    let isLocal = host.includes('localhost') || host.includes('dev-') || host.includes('staging-')
    let isProduction = !isLocal
    return isProduction
}


/**
 * Initializes Google Analytics, but only if app is running in Production.
 *
 * @exports initGoogleAnalytics
 * @param {String} host Host where the app is running.
 * @return {Object} Google Analytics instance
*/
export function initGoogleAnalytics(host) {
    if (isProduction(host)) {
        let google = analytics["google"]
        ReactGA.initialize(
            [
                {
                    trackingId: google["production"],
                    gaOptions: { name: 'production' }
                },
                {
                    trackingId: google["alternate"],
                    gaOptions: { name: 'alternate' }
                }
            ]
        )
    }
}


/**
 * Handles sending page views to Google Analytics.
 *
 * @exports sendPageview
 * @param {String} host The site host where the app is running.
 * @param {String} page App page.
 * @return {Object} React-GA pageview data for Google Analytics.
*/
export function sendPageview(host, page) {
    if (isProduction(host)) { ReactGA.pageview(page, trackingIds) }
}


/**
 * Handles sending user events to Google Analytics.
 *
 * @exports sendGAUserEvent
 * @param {String} host The site host where the app is running.
 * @param {String} message User event message.
 * @return {Object} React-GA user event data for Google Analytics.
*/
export function sendGAUserEvent(host, message) {
    if (isProduction(host)) {
        ReactGA.event({
            category: 'User',
            action: message
        }, trackingIds)
    }
}