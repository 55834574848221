export default function reducer(state, { type, payload }) {
    switch (type) {
        case 'SET_FREE_TRIAL_MODAL_STATE':
            return {
                ...state,
                freeTrial: {
                    isModalOpen: payload.isModalOpen,
                },
            }
        default:
            return state
    }
}